
import { Layout } from "../layout/Layout";
import { View } from "../views/View";

export const Page = () => {
  console.log("PAGE")
  return (
    <Layout>
        <View/>
    </Layout>
  )
}
