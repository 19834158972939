import React              from 'react';
import ReactDOM           from 'react-dom/client';
import { BrowserRouter }  from 'react-router-dom';
import { Provider }       from 'react-redux';
import {JournalApp}       from './JournalApp';
import reportWebVitals    from './reportWebVitals';
import './styles.css';
import { store } from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <JournalApp />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
