
const global = {
    "token"         : "",
    "name_user"     : "",
    "email"         : "",
    "validateToken" : "",
    "url"           : "http://ecommerce/public/",
    /*"urlproduction" : "https://ecommerce.sergiodevsolutions.com/"*/
}

export default global;